<template>
  <div class="section__lk-content container-shadow fill-white">
    <div class="section__lk-title">Мои уведомления</div>
    <div class="section__lk-subtitle">Список уведомлений</div>
    <div v-if="!allNotes.total" class="section__lk-empty">пока у вас нет ни одного уведомления</div>
    <template v-else>
      <div v-for="message in allNotes.messages" :key="message.id" class="section__lk-course">
        <img style="width: 32px;" src="/pic/profile/bell.svg" />
        <a :href="mapping(message.link)" target="_blank" @click="readNotify(message)" class="section__lk-course-title">
          {{ message.title }}
        </a>
        <div class="section__lk-course-date">
          {{ message.modified_at | formatDate('DD.MM.YYYY HH:mm') }}
        </div>
        <div :style="{opacity: message.is_viewed ? 0 : 1}" class="section__lk-order-status"><div class="status status_warning"></div></div>
      </div>
      <div class="load-more__wrapper mb-2 mt-2" v-if="showLoadMore">
        <button class="btn btn-primary" @click="offset += allNotes.limit">
          <span></span> Загрузить ещё
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ApiService from "../../../services/api.service";
const LIMIT = 10

export default {
  name: "Notification",
  data() {
    return {
      offset: 0
    }
  },
  mounted() {
    this.getAllNotes({limit: LIMIT})
  },
  computed: {
    ...mapGetters(['allNotes']),
    showLoadMore() {
      return this.allNotes.total > this.allNotes.messages.length
    }
  },
  methods: {
    ...mapActions(['getAllNotes', 'getNotes']),
    async readNotify(message) {
      await ApiService.put('notifications/' + message.id, {
        isViewed: true
      })
      message.is_viewed = true
      await this.getNotes()
    },
    mapping(link) {
      switch (link) {
        case '/program/subscribe':
          return '/lk/online';
        default:
          return link.replace('/subscribe', '');
      }
    }
  },
  watch: {
    offset(val) {
      this.getAllNotes({limit: LIMIT, offset: val})
    }
  }
}
</script>

<style scoped>

</style>